

























import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import Card from '@/components/Card.vue'

@Component({
  components: { Card },
})
export default class Home extends Vue {
  get hasGeolocation() {
    return !!navigator.geolocation && window.location.protocol.includes('https')
  }

  get locationHint() {
    return !this.hasGeolocation
      ? 'Long-press anywhere on the map'
      : 'Tap the compass arrow (or long-press anywhere on the map)'
  }
}
