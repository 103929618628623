











































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class BottomSheet extends Vue {
  @Prop({ default: false }) peek!: boolean

  @Ref('sheet') sheetEl!: HTMLElement
  @Ref('sheetMargin') sheetMarginEl!: HTMLElement
  @Ref('stopTop') stopTopEl!: HTMLElement
  @Ref('stopMid') stopMidEl!: HTMLElement
  @Ref('stopBottom') stopBottomEl!: HTMLElement
  @Ref('scrollMargin') scrollMarginEl!: HTMLElement

  scrolled = false
  scrollTop = 0

  height = 0
  stop: 'top' | 'middle' | 'bottom' | 'between' = 'bottom'

  willDismiss = false
  dismissed = false

  willInteract = true
  interactive = false

  get classes() {
    return [
      {
        _interactive: this.interactive,
        _scrolled: this.scrolled,
        _top: this.atTop || this.scrolled,
        _mid: this.atMid,
        _bottom: this.atBottom,
      },
    ]
  }

  mounted() {
    this.$root.$on('raisesheet', () => this.atBottom && this.toMid())
    this.$root.$on('lowersheet', () => !this.atBottom && this.toBottom())
    this.$root.$on('togglesheet', this.toggle)
    this.$root.$on('checksheet', this.check)
    this.onScroll()
  }

  activate() {
    this.interactive = this.willInteract
  }

  deactivate() {
    this.interactive = false
  }

  dismiss() {
    if (!this.dismissed) {
      this.dismissed = true
      this.$emit('dismiss')
    }
  }

  check() {
    // This is a collection of hacks trying to fix issues relating to
    // adjusting the scroll height based on content of the sheet
    // They don’t really work

    // Safari is fine until the user has scrolled the sheet
    // then it seems to need a kick
    if (this.isSafari) {
      window.dispatchEvent(new Event('scroll'))
    }

    // The new scroll height tends to end up between
    // when it should be in the middle
    if (this.atBetween) {
      this.toMid()
    }

    // Firefox responds to this
    if (this.isFirefox) {
      this.sheetEl.scroll()
    }
  }

  // Sniffs

  get isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }

  get isFirefox() {
    return 'netscape' in window
  }

  // Stops

  toggle() {
    if (this.atMid) {
      this.toTop()
    } else {
      this.toMid()
    }
  }

  toTop() {
    this.sheetEl.scrollTop = this.stopTopEl.offsetTop
    this.activate()
  }

  toMid() {
    this.sheetEl.scrollTop = this.stopMidEl.offsetTop
  }

  toBottom() {
    this.sheetEl.scrollTop = this.stopBottomEl.offsetTop
  }

  setStop(stop: 'top' | 'middle' | 'bottom' | 'between') {
    this.stop = stop
    this.$store.dispatch('sheetScrolled', { stop, height: this.height })
  }

  get atTop() {
    return this.stop === 'top'
  }

  get atMid() {
    return this.stop === 'middle'
  }

  get atBottom() {
    return this.stop === 'bottom'
  }

  get atBetween() {
    return this.stop === 'between'
  }

  // Events

  onEnter() {
    this.peek ? this.toBottom() : this.toMid()
  }

  onScroll() {
    if (this.sheetEl && !this.dismissed) {
      const delta = this.sheetEl.scrollTop - this.scrollTop
      const top = this.sheetMarginEl.clientHeight
      const context = window.innerHeight - this.stopTopEl.offsetTop

      this.scrolled = false
      this.scrollTop = this.sheetEl.scrollTop
      this.height = this.scrollTop + context

      switch (true) {
        case this.scrollTop >= top:
          this.setStop('top')
          this.scrollMarginEl.style.height = '0'
          if (this.scrollTop > top) {
            this.scrolled = true
          }
          return
        case this.scrollTop === this.stopMidEl.offsetTop:
          this.setStop('middle')
          break
        case this.scrollTop <= 0:
          this.setStop('bottom')
          break
        default:
          this.setStop('between')
          break
      }

      if (this.willDismiss) {
        const minHeight = this.stopBottomEl.offsetTop / 2
        if (delta < 0 && this.sheetEl.scrollTop < minHeight) {
          this.scrollMarginEl.style.height = '0'
          this.dismiss()
          return
        }
      }

      this.scrollMarginEl.style.height = `${this.sheetEl.scrollTop}px`
    }
  }
}
